import React, { Fragment } from "react"
import video from "../assets/intro_cks.mp4"
import csn from "../assets/logos/logo_csn.jpg"
import cbc from "../assets/logos/cbc.jpg"
import rugby from "../assets/logos/rugby.jpeg"
import insep from "../assets/logos/insep.png"
import caen_training from "../assets/logos/caen_training.png"
import { FaLocationArrow, FaRegClock, FaUnity } from "react-icons/fa"
import HomePageCard from "./tools/HomePageCard"
import { NavLink, Link } from "react-router-dom"

const Homepage = () => {
	const iconStyle = { height: "100px", width: "100px" }
	return (
		<Fragment>
			<div className="container-fluid">
				<div
					id="homePageCarousel"
					className="carousel slide"
					data-bs-ride="carousel"
				>
					<div className="carousel-inner">
						<div className="carousel-item active">
							<video
								className="d-block w-100 img-fluid"
								alt="présentation de Caen Kiné Sports"
								autoPlay={true}
								loop={true}
								muted={true}
								playsInline={true}
							>
								<source src={video} type="video/mp4" />
							</video>
						</div>
					</div>
				</div>
			</div>

			<section className="nos-prestations">
				<div className="container my-2">
					<p className="h2 text-center my-1 text-info">Nos Prestations</p>
					<p className="text-center my-1">
						Caen Kiné Sports est spécialisé dans la rééducation et
						l'accompagnement de sportifs de haut niveau. Avec un plateau
						technique de plus 200m<sup>2</sup> et des équipements modernes et
						dans l'ère de la technologie. Notre équipe assure le suivi de
						nombreux athlètes et sportifs de la région ainsi que de nombreuses
						équipes professionnelles. Rééducation, ostéopathie, remise en forme
						sont des domaines dans lesquels nous pouvons vous apporter notre
						expertise.
					</p>
				</div>
			</section>
			<section className="nos-partenaires">
				<div className="container my-3">
					<div className="h2 text-center my-1 text-info">Nos Partenaires</div>
					<div className="row row-cols-lg-5 row-cols-md-3 row-cols-sm-3 row-cols-3 justify-content-evenly p-3 ">
						<Link className="text-decoration-none" to="https://caenbc.com/">
							<div className="col">
								<img
									src={cbc}
									style={iconStyle}
									className="img-fluid my-2"
									alt="logo du CBC"
								/>
							</div>
						</Link>
						<Link
							className="text-decoration-none"
							to="https://www.sport-normandie.fr/"
						>
							<div className="col">
								<img
									src={csn}
									style={iconStyle}
									className="img-fluid my-2"
									alt="logo du CBC"
								/>
							</div>
						</Link>
						<Link className="text-decoration-none" to="https://scrc.ffr.fr/">
							<div className="col">
								<img
									src={rugby}
									style={iconStyle}
									className="img-fluid my-2"
									alt="logo du CBC"
								/>
							</div>
						</Link>
						<Link
							className="text-decoration-none"
							to="https://www.caentraining.com/"
						>
							<div className="col">
								<img
									src={caen_training}
									style={iconStyle}
									className="img-fluid my-2"
									alt="logo du CBC"
								/>
							</div>
						</Link>
						<Link
							className="text-decoration-none"
							to="https://www.insep.fr/fr/reseaux/label-grand-insep"
						>
							<div className="col">
								<img
									src={insep}
									style={iconStyle}
									className="img-fluid my-2"
									alt="logo du CBC"
								/>
							</div>
						</Link>
					</div>
				</div>
			</section>
			<section className="nos-services">
				<div className="container my-2 ">
					<NavLink to="/les-therapeutes" className="text-decoration-none">
						<div className="row text-center">
							<HomePageCard
								headerPic=<FaUnity />
								headerTitle="Les thérapeutes"
								content="Notre équipe peut assurer votre suivi en terme de rééducation, remise en forme, prévention des blessures, accompagnent post-partum, kinésithérapie respiratoire de l'adulte et du nourrison."
							/>
						</div>
					</NavLink>
					<div className="row text-center">
						<HomePageCard
							headerPic=<FaRegClock />
							headerTitle="Horaires"
							content="Le cabinet vous accueille du lundi au vendredi de 8h à 20h.  "
						/>
					</div>
					<Link
						to="https://www.google.fr/maps/place/CAEN+KINE+SPORTS/@49.1755681,-0.4393914,17z/data=!3m1!4b1!4m6!3m5!1s0x480a44bcaacbfe3b:0x19c1965697748ecb!8m2!3d49.1755681!4d-0.4368165!16s%2Fg%2F11cs3z73k4?entry=ttu"
						className="text-decoration-none"
					>
						<div className="row text-center">
							<HomePageCard
								headerPic=<FaLocationArrow />
								headerTitle="Accès"
								content="12 rue Elise Deroche, Quartier Koenig – 14760 Bretteville-sur-Odon <br/> (Possibilité de se garer facilement à proximité du cabinet) <br/> Twisto : Arrêt de bus Koenig"
							/>
						</div>
					</Link>
				</div>
			</section>
		</Fragment>
	)
}

export default Homepage
