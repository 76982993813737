import vincePic from "../../assets/teamPictures/Vincent.jpg"
import nicoPic from "../../assets/teamPictures/Nicolas_2.jpg"
import matthieuPic from "../../assets/teamPictures/Matthieu.jpg"
import jeannePic from "../../assets/teamPictures/Jeanne2.jpg"
import adrienPic from "../../assets/teamPictures/Adrien2.jpg"
import marionPic from "../../assets/teamPictures/Marion2.jpg"
import celinePic from "../../assets/teamPictures/Céline2.jpg"
import antoinePic from "../../assets/teamPictures/Antoine2.jpg"
import eugeniePic from "../../assets/teamPictures/Eugénie2.jpg"
import luciePic from "../../assets/teamPictures/Lucie.jpg"
import benjaminPic from "../../assets/teamPictures/Benjamin.jpg"
import mariePic from "../../assets/teamPictures/Marie.jpg"
import leoniePic from "../../assets/teamPictures/Léonie.jpg"

const vincent = {
	name: "Vincent Alléaume",
	picture: vincePic,
	accomplished: [
		"Masseur Kinésithérapeute D.E (2011)",
		"Formation en Kinésithérapie Respiratoire du Nourrisson (2012)",
		"Diplômé en Thérapie Manuelle (2014)",
		"Titulaire d’un D.U de Kinésithérapie du Sport (2015)",
		"Titulaire d’un D.U d'Isocinétisme (2016)",
		"Diplôme de Préparation physique (2017)",
	],
	localKey: "x1",
}

const nicolas = {
	name: "Nicolas Lanos",
	picture: nicoPic,
	accomplished: [
		"Masseur Kinésithérapeute D.E (2009)",
		"Ostéopathe D.E (2014)",
		"D.U de Kinésithérapie du Sport (2015)",
		"Diplôme de Préparation physique (2017)",
		"Titulaire d’un D.U d'Isocinétisme (2018)",
	],
	localKey: "x2",
}
const matthieu = {
	name: "Matthieu Launoy",
	picture: matthieuPic,
	accomplished: [
		"Masseur Kinésithérapeute D.E (2009)",
		"Formation en Kinésithérapie Respiratoire (2013)",
		"Formation Prévention des blessures en course à pied : la clinique du coureur (2017)",
		"Formation kinésithérapeute du sport Kinesport (2017)",
		"Formation Complexe Global de l’Epaule (2017)",
		"D.U d’Isocinétisme (2018)",
		"K-Start, tests fonctionnels du genou (2018)",
		"Dry needling (2023)",
	],
	localKey: "x3",
}
const jeanne = {
	name: "Jeanne Bonnaudin",
	picture: jeannePic,
	accomplished: [
		"Masseur Kinésithérapeute D.E (2017)",
		"Formation en Kinésithérapie Respiratoire du Nourrisson ",
		"Diplômé en Thérapie Manuelle ",
		"Empilement articulaire dynamique",
		"Formation Prévention des blessures en course à pied : la clinique du coureur",
		"kinésithérapie du sport (2020)",
		"S-Starts , retour eu sport après chirurgie de l'épaule",
		"Complexe de l'épaule",
		"Formation neurodynamics",
		"Dry needling (2023)",
	],
	localKey: "x4",
}
const adrien = {
	name: "Adrien Mazeaud",
	picture: adrienPic,
	accomplished: [
		"Masseur Kinésithérapeute D.E",
		"K-Start, tests fonctionnels du genou",
		"Formation kinésithérapeute du sport Kinesport",
		"Thérapie manuelle",
		"Formation CGE",
		"Formation Prévention des blessures en course à pied : la clinique du coureur",
		"Formation Empilement articulaire dynamique",
	],
	localKey: "x5",
}
const marion = {
	name: "Marion Lecler",
	picture: marionPic,
	accomplished: [
		"Masseur-Kinésithérapeute D.E (2008)",
		"Formation kinésithérapeute du sport Kinesport",
		"Formation Prévention des blessures en course à pied : la clinique du coureur",
		"Formation complexe globale de l'épaule",
		"K-Start test fonctionnel du genou",
		"Prise en charge des lésions des ischio-jambiers",
		"Neuropathies, neurodynamique et syndromes canalaires",
	],
	localKey: "x6",
}
const celine = {
	name: "Céline Nativelle",
	picture: celinePic,
	accomplished: [
		"Masseur-Kinésithérapeute D.E (2012)",
		"Formation en kinésithérapie respiratoire du nourrisson et de l'adulte et BPCO",
		"Formation K-Taping (2014)",
		"Formation en kinésithérapie vestibulaire (2016)",
		"Formation en kinésithérapie périnéale (2018)",
		"Formation en kinésithérapie prénatale et obstétricale (IPPP)",
		"Accompagnement et préparation à l'accouchement (IPPP & EIRPP)",
		"Formation en kinésithérapie du sport et thérapie manuelle",
		"Formation en kinésithérapie viscérale (2020)",
		"Formation Pilates Polestar (2020)",
	],
	localKey: "x7",
}
const eugenie = {
	name: "Eugénie Philippart",
	picture: eugeniePic,
	accomplished: [
		"Masseur-Kinésithérapeute D.E (2011)",
		"Formation en kinésithérapie respiratoire du nourrisson (2011)",
		"Formation en rééducation périnéale et abdominale en hypopressif (post partum) (2011)",
		"Formation complexe globale de l'épaule (2015)",
		"Formation en thérapie manuelle du sport (2015)",
		"Formation Prévention des blessures en course à pied : la clinique du coureur (2017)",
		"Formation Empilement articulaire dynamique (2018)",
		"Accompagnement prénatal",
		"Dry needling (2023)",
	],
	localKey: "x8",
}
const antoine = {
	name: "Antoine Pinel",
	picture: antoinePic,
	accomplished: [
		"Masseur Kinésithérapeute D.E (2018)",
		"Formation en articulation temporo-mandibulaire (2019)",
	],
	localKey: "x9",
}
const leonie = {
	name: "Léonie Jenvrin",
	picture: leoniePic,
	accomplished: [
		"Masseur Kinésithérapeute D.E (2021)",
		"Kinésithérapie du sport (2023)",
		"Drainage kinésithérapie respiratoire nourrisson (2022)",
		"EBP Epaule (2023)",
	],
	localKey: "x10",
}
const lucie = {
	name: "Lucie Marie",
	picture: luciePic,
	accomplished: [
		"Masseur Kinésithérapeute D.E (2021)",
		"Kinésithérapie du sport Expert (2022)",
		"Neurodynamique (2023)",
	],
	localKey: "x11",
}
const benjamin = {
	name: "Benjamin Lemaréchal",
	picture: benjaminPic,
	accomplished: [
		"Masseur Kinésithérapeute D.E (2015)",
		"Formation en articulation temporo-mandibulaire (2019)",
		"Kinésithérapie du sport",
		"Dry-needling (2023)",
	],
	localKey: "x12",
}
const marie = {
	name: "Marie François",
	picture: mariePic,
	accomplished: [
		"Masseur Kinésithérapeute D.E (2022)",
		"L'épaule, théorie et pratique",
	],
	localKey: "x13",
}

const Team = [
	vincent,
	nicolas,
	matthieu,
	adrien,
	jeanne,
	marion,
	eugenie,
	celine,
	antoine,
	benjamin,
	leonie,
	marie,
	lucie,
]

export default Team
