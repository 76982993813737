import React from "react"
import balneo1 from "../assets/officePictures/Locaux_balnéo.jpg"
import balneo2 from "../assets/officePictures/Locaux_balneo1.jpg"
import balneo3 from "../assets/officePictures/Locaux_balneo2.jpg"
import Carousel from "./tools/Carousel"
import { NavLink } from "react-router-dom"

const BalneoPresentation = () => {
	const pictures = [balneo1, balneo2, balneo3]

	return (
		<div>
			<nav aria-label="breadcrumb">
				<ol className="breadcrumb ms-2">
					<li className="breadcrumb-item">
						<NavLink className="text-decoration-none" to="/">
							<small>Accueil</small>
						</NavLink>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						<small>Espace balnéothérapie</small>
					</li>
				</ol>
			</nav>
			<Carousel pics={pictures} page="balneo" />
			<div className="container">
				<h1 className="text-info my-2 text-center">Espace Balnéothérapie</h1>
				<p className="text-center">
					Cet espace est constitué d'un sauna, d'une piscine chauffée à 31°C et
					d'un bain froid.
				</p>
			</div>
		</div>
	)
}

export default BalneoPresentation
