import React from "react"
import { Link } from "react-router-dom"

const PrivatePolicy = () => {
	return (
		<div>
			<h1 className="text-center text-info my-2">Mentions légales</h1>
			<p className="ms-1 h2"> Editeur </p>
			<p className="ms-2">
				CAEN KINE SPORTS <br /> 12 rue Elise Deroche Quartier Koenig 14760
				Bretteville-sur-Odon <br />
				09 83 40 25 30 <br />
				caenkinesports@gmail.com <br /> SELARL au capital social de 27 000 euros
				immatriculé au Greffe du Tribunal de Commerce de Caen sous le numéro 808
				460 588 00029. <br />
				Ce site est conçu et developpé par CAEN KINE SPORTS
			</p>
			<p className="ms-1 h2">Hébergeur</p>
			<p className="ms-2"> SAS OVH 2, rue Kellermann BP 80157 59100 Roubaix</p>
			<h2 className="ms-1 ">Praticiens </h2>
			<ul className="list-group ms-1 ">
				<li className="list-group-item border-0">
					Vincent Alleaume, Masseur Kinésithérapeute D.E depuis 2011 – Inscrit
					sous le numéro 538 448 612
				</li>
				<li className="list-group-item border-0">
					Nicolas Lanos, Masseur kinésithérapeute D.E depuis 2009 – Inscrit sous
					le numéro 505 125 849
				</li>
				<li className="list-group-item border-0">
					{" "}
					Matthieu Launoy, Masseur kinésithérapeute D.E depuis 2009 – Inscrit
					sous le numéro 794 179 275
				</li>
				<li className="list-group-item border-0">
					{" "}
					Eugénie Philippart, Masseur Kinésithérapeute D.E depuis 2011 –
					Inscrite sous le numéro 534 221 551
				</li>
				<li className="list-group-item border-0">
					Adrien Mazeaud, Masseur-kinésithérapeute D.E – Inscrit sous le numéro
					523 712 768
				</li>
				<li className="list-group-item border-0">
					{" "}
					Jeanne Bonnaudin, Masseur-kinésithérapeute D.E – Inscrite sous le
					numéro 830 398 574
				</li>
				<li className="list-group-item border-0">
					Marion Lecler, Masseur-kinésithérapeute D.E – Inscrite sous le numéro
					510 622 905
				</li>
				<li className="list-group-item border-0">
					{" "}
					Céline Nativelle, Masseur-kinésithérapeute D.E – Inscrite sous le
					numéro 800 316 945
				</li>
				<li className="list-group-item border-0">
					{" "}
					Antoine Pinel, Masseur-kinésithérapeute D.E – Inscrit sous le numéro
					844 742 908
				</li>
			</ul>
			<p className="h2 ms-1 mt-2">Propriété intellectuelle</p>
			<p className="ms-2">
				Tout contenu et information de caenkinesports.fr est protégé par le
				droit sur la propriété intellectuelle. Toute reproduction,
				représentation ou utilisation, intégrale ou partielle, des éléments
				constitutifs de ce site, sur quelque support que ce soit, est interdite
				sans l’autorisation expresse et préalable de Caen Kiné Sports <br />{" "}
				Caen Kiné Sports est une marque déposée. La violation de la propriété
				intellectuelle et du droit d’auteur est sanctionnée par les articles du
				Code de la Propriété Intellectuelle L. 335-2 et suivant. Les
				contrevenants feront l’objet de poursuites judiciaires.
			</p>
			<p className="h2 ms-1 mt-2">Données personnelles</p>
			<p className="ms-2">
				Les données personnelles et informations recueillies lors de vos
				consultations sont destinées à un usage interne uniquement. Elles feront
				l’objet d’un enregistrement informatique et seront conservées sans
				condition de délai. Vous pouvez demander à tout moment d’accéder à vos
				données personnelles, afin de procéder à leur modification, leur
				rectification, leur limitation ou leur suppression (loi « Informatique
				et Libertés » du 6 janvier 1978 modifiée) .<br />
				Si vous souhaitez vous opposer à ce traitement, vous pouvez formuler
				votre demande par mail à
				<Link
					to="mailto: caenkinesports@gmail.com"
					className="text-decoration-none"
				>
					{" "}
					caenkinesports@gmail.com
				</Link>{" "}
				ou par courrier : <br /> 12 rue Elise Deroche Quartier Koenig 14760
				Bretteville-sur-Odon{" "}
			</p>
		</div>
	)
}

export default PrivatePolicy
