import React from "react"
import { NavLink, Link } from "react-router-dom"
import facebook from "../assets/icons/facebook.png"
import instagram from "../assets/icons/instagram.png"
import logo from "../assets/logos/logo_clean.png"
import { BiMailSend, BiPhoneCall, BiLocationPlus } from "react-icons/bi"
import { FaLinkedin } from "react-icons/fa"
import { IconContext } from "react-icons"

const Footer = () => {
	const email = "caenkinesports@gmail.com"
	const phonenumber = "09 83 40 25 30 "

	return (
		<div className="bg-info p-3 text-center mt-5">
			<p>
				<a
					href="https://www.instagram.com/caen_kine_sports/?hl=fr"
					className="link-light text-decoration-none mx-2"
				>
					<img src={instagram} alt="instagram caen kiné sports" />
				</a>
				<a
					href="https://www.facebook.com/caenkinesports/?locale=fr_FR"
					className="link-light text-decoration-none"
				>
					<img src={facebook} alt="facebook caen kiné sports" />
				</a>
			</p>
			<div className="text-center">
				<Link className="link-light text-decoration-none" to="/">
					<img
						src={logo}
						alt="logo CKS"
						style={{ width: "68px", height: "35px" }}
					/>
				</Link>
				<br />
				<Link
					className="link-light text-decoration-none"
					to="https://www.google.fr/maps/place/CAEN+KINE+SPORTS/@49.1755681,-0.4393914,17z/data=!3m1!4b1!4m6!3m5!1s0x480a44bcaacbfe3b:0x19c1965697748ecb!8m2!3d49.1755681!4d-0.4368165!16s%2Fg%2F11cs3z73k4?entry=ttu"
				>
					<IconContext.Provider value={{ size: "1.5em" }}>
						<BiLocationPlus className="text-dark" />
					</IconContext.Provider>
					12 rue Elise Deroche Quartier Koenig 14760 Bretteville-sur-Odon
				</Link>
				<br />
				<Link
					to={`mailto:${email}`}
					className="text-decoration-none link-light"
				>
					<BiMailSend className="text-dark" /> {email}
				</Link>
				<br />
				<Link
					to={`tel:${phonenumber}`}
					className="text-decoration-none link-light"
				>
					<BiPhoneCall className="text-dark" /> {phonenumber}
				</Link>
			</div>
			<br />
			<NavLink className="link-light text-decoration-none mt-2" to="/contact">
				Contact
			</NavLink>
			<br />
			<NavLink
				className="link-light text-decoration-none"
				to="/mentions-legales"
			>
				Mentions légales
			</NavLink>
			<br />
			<Link className="link-light text-decoration-none">
				<small>&copy; CKS</small>
			</Link>
			<br />
			<Link
				className="link-secondary text-decoration-none mt-5"
				to="https://www.linkedin.com/in/diame-mo%C3%AFse-6bb47484/"
			>
				<FaLinkedin className="text-secondary" />{" "}
				<small>Site conçu par Moïse DIAME</small>
			</Link>
		</div>
	)
}

export default Footer
