import React, { Fragment } from "react"

const Carousel = (props) => {
	const { pics, page } = props
	return (
		<Fragment>
			<div
				id={`carouselSlides${page}`}
				className="carousel slide carousel-fade"
				data-bs-ride="carousel"
			>
				<div className="carousel-inner">
					{pics.map((picture, index) => (
						<div
							className={`carousel-item ${index === 0 ? "active" : ""}`}
							key={index}
						>
							<img
								src={picture}
								className="d-block w-100 img-fluid"
								alt="plateau du cabinet CKS"
							/>
						</div>
					))}
				</div>
				<button
					className="carousel-control-prev"
					type="button"
					data-bs-target={`#carouselSlides${page}`}
					data-bs-slide="prev"
				>
					<span
						className="carousel-control-prev-icon"
						aria-hidden="true"
					></span>
					<span className="visually-hidden">Previous</span>
				</button>
				<button
					className="carousel-control-next"
					type="button"
					data-bs-target={`#carouselSlides${page}`}
					data-bs-slide="next"
				>
					<span
						className="carousel-control-next-icon"
						aria-hidden="true"
					></span>
					<span className="visually-hidden">Next</span>
				</button>
			</div>
		</Fragment>
	)
}

export default Carousel
