import "bootstrap/dist/css/bootstrap.min.css"
import "./App.css"
import Menu from "./components/Navbar"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Footer from "./components/Footer"
import Homepage from "./components/Homepage"
import OfficePresentation from "./components/OfficePresentation"
import TeamPresentation from "./components/TeamPresentation"
import BalneoPresentation from "./components/BalneoPresentation"
import CryoPresentation from "./components/CryoPresentation"
import ContactUs from "./components/ContactUs"
import PrivatePolicy from "./components/PrivatePolicy"

function App() {
	return (
		<BrowserRouter>
			<Menu />

			<Routes>
				<Route path="/" element={<Homepage />} />
				<Route path="/les-therapeutes" element={<TeamPresentation />} />
				<Route path="/le-cabinet" element={<OfficePresentation />} />
				<Route path="/balneotherapie" element={<BalneoPresentation />} />
				<Route path="/cryotherapie" element={<CryoPresentation />} />
				<Route path="/contact" element={<ContactUs />} />
				<Route path="/mentions-legales" element={<PrivatePolicy />} />
			</Routes>

			<Footer />
		</BrowserRouter>
	)
}

export default App
