import React, { Fragment } from "react"

const CryoPrices = () => {
	return (
		<Fragment>
			<table className="table text-center table-hover table-borderless">
				<tbody>
					<tr className="">
						<td>1 séance</td>
						<td>40€</td>
					</tr>
					<tr>
						<td>10 séances</td>
						<td>350€</td>
					</tr>
				</tbody>
			</table>
		</Fragment>
	)
}

export default CryoPrices
