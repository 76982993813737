import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import Offcanvas from "react-bootstrap/Offcanvas"
import React, { Fragment } from "react"
import logo from "../assets/logos/logo_clean.png"
import facebook from "../assets/icons/facebook.png"
import instagram from "../assets/icons/instagram.png"

function Menu() {
	return (
		<Fragment>
			<Navbar
				expand="lg"
				className="bg-light mb-3 "
				collapseOnSelect={true}
				sticky="top"
			>
				<Container fluid>
					<Navbar.Toggle aria-controls="offcanvasNavbar-expand" />
					<Navbar.Brand href="/" className="mx-auto">
						<img
							src={logo}
							style={{
								maxWidth: "135px",
								maxHeight: "70px",
								width: "auto",
								height: "auto",
							}}
							alt="logo Caen Kiné Sports"
						/>{" "}
					</Navbar.Brand>
					<Navbar.Offcanvas
						id="offcanvasNavbar-expand"
						aria-labelledby="offcanvasNavbarLabel-expand"
						placement="start"
					>
						<Offcanvas.Header closeButton>
							<Offcanvas.Title
								id="offcanvasNavbarLabel-expand"
								className="mx-auto"
							>
								<img
									src={logo}
									style={{
										maxWidth: "135px",
										maxHeight: "70px",
										width: "auto",
										height: "auto",
									}}
									alt="logo Caen Kiné Sports"
								/>
							</Offcanvas.Title>
						</Offcanvas.Header>
						<Offcanvas.Body>
							<Nav className="justify-content-center flex-grow-1 pe-3 ms-1">
								<Nav.Link href="/" className="fs-5 text-primary">
									Accueil
								</Nav.Link>
								<Nav.Link href="/le-cabinet" className="fs-5 text-primary">
									Le Cabinet
								</Nav.Link>
								<Nav.Link href="/les-therapeutes" className="fs-5 text-primary">
									L'Equipe
								</Nav.Link>
								<Nav.Link href="/balneotherapie" className="fs-5 text-primary">
									Balnéothérapie
								</Nav.Link>
								<Nav.Link href="/cryotherapie" className="fs-5 text-primary">
									Cryothérapie
								</Nav.Link>
								<Nav.Link href="/contact" className="fs-5 text-primary">
									Nous Contacter
								</Nav.Link>
							</Nav>
						</Offcanvas.Body>
					</Navbar.Offcanvas>
					<Nav.Link
						href="https://www.instagram.com/caen_kine_sports/?hl=fr"
						className="link-light text-decoration-none mx-1"
					>
						<img src={instagram} alt="instagram caen kiné sports" />
					</Nav.Link>
					<Nav.Link
						href="https://www.facebook.com/caenkinesports/?locale=fr_FR"
						className="link-light text-decoration-none"
					>
						<img src={facebook} alt="facebook caen kiné sports" />
					</Nav.Link>
				</Container>
			</Navbar>
		</Fragment>
	)
}

export default Menu
