import React from "react"
import { IconContext } from "react-icons"

const HomePageCard = (props) => {
	const { headerPic, headerTitle, content } = props
	return (
		<div>
			<div className="card border-0">
				<div className="card-img-top">
					{" "}
					<IconContext.Provider value={{ size: "2em" }}>
						{headerPic}
					</IconContext.Provider>
				</div>
				<div className="card-body">
					<p className="card-title text-info h4">{headerTitle}</p>
					<p
						className="card-text text-center"
						dangerouslySetInnerHTML={{ __html: content }}
					></p>
				</div>
			</div>
		</div>
	)
}

export default HomePageCard
