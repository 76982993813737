import React from "react"
import { NavLink } from "react-router-dom"
import Carousel from "./tools/Carousel"
import cryo1 from "../assets/officePictures/Locaux_cryo.jpg"
import cryo2 from "../assets/officePictures/Locaux_cryo1.jpg"
import cryo3 from "../assets/officePictures/Locaux_cryo2.jpg"
import CryoPrices from "./tools/CryoPrices"

const CryoPresentation = () => {
	const pictures = [cryo3, cryo2, cryo1]
	return (
		<div>
			<nav aria-label="breadcrumb">
				<ol className="breadcrumb ms-2">
					<li className="breadcrumb-item">
						<NavLink className="text-decoration-none" to="/">
							<small>Accueil</small>
						</NavLink>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						<small>Cryothérapie</small>
					</li>
				</ol>
			</nav>
			<div className="container">
				<h1 className="text-info my-2 text-center">Cryothérapie</h1>
				<p className="text-center">
					Les bienfaits de la cryothérapie sont diverses... Vous avez la
					possibilité de bénificier de séances de cryothérapie allant jusque
					-110°C. Venez optimiser votre récupération...
				</p>
			</div>
			<Carousel pics={pictures} page="cryo" />
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-6 col-lg-4 col-sm-8 col-12">
						<h2 className="text-center text-info my-2"> Les tarifs</h2>
						<CryoPrices />
					</div>
				</div>
			</div>
		</div>
	)
}

export default CryoPresentation
