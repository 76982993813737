import React from "react"
import { NavLink } from "react-router-dom"
import PresentationCards from "./tools/PresentationCard"

const TeamPresentation = () => {
	return (
		<div>
			<nav aria-label="breadcrumb">
				<ol className="breadcrumb ms-2">
					<li className="breadcrumb-item">
						<NavLink className="text-decoration-none" to="/">
							<small>Accueil</small>
						</NavLink>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						<small>L'Equipe</small>
					</li>
				</ol>
			</nav>
			<h1 className="text-center text-info h2">Equipe Caen Kiné Sports</h1>
			<div className="container text-center">
				<p className="text-center">
					Voici notre équipe de masseurs-kinésithérapeutes,ils pourront vous
					accompagner et vous conseiller en rééducation fonctionnelle,
					accompagnement post-partum,remise en forme,prévention des blessures,
					kinésithérapie respiratoire etc.
				</p>
			</div>
			<PresentationCards />
		</div>
	)
}

export default TeamPresentation
