import React from "react"
import Carousel from "./tools/Carousel"
import cabPic1 from "../assets/officePictures/Locaux_cabinet.jpg"
import cabPic2 from "../assets/officePictures/Locaux_cabinet1.jpg"
import cabPic3 from "../assets/officePictures/Locaux_cabinet2.jpg"
import cabPic4 from "../assets/officePictures/Locaux_cabinet_iso.jpg"
import { Link, NavLink } from "react-router-dom"

const OfficePresentation = () => {
	const pictures = [cabPic1, cabPic2, cabPic3, cabPic4]
	return (
		<div>
			<nav aria-label="breadcrumb">
				<ol className="breadcrumb ms-2">
					<li className="breadcrumb-item">
						<NavLink className="text-decoration-none" to="/">
							<small>Accueil</small>
						</NavLink>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						<small>Le Cabinet</small>
					</li>
				</ol>
			</nav>
			<Carousel pics={pictures} page="cabinet" />
			<div className="container">
				<h1 className="text-info my-2 text-center">Le Cabinet</h1>
				<p className="text-center">
					Le cabinet est disposé sur un espace de plus de 500m<sup>2</sup> et
					doté de matériels de haute qualité. Nous disposons de six salles
					privatives dédiées aux soins individuels. <br /> Le plateau technique
					de près de 200m<sup>2</sup> est équipé d'appareils de toute dernière
					génération aussi bien terme de renforcement musculaire,
					réathlétisation, rééducation fonctionnelle, récupération par
					pressothérapie etc. <br />
					Vous pourrez également profiter également d'un espace de{" "}
					<Link to="/balneotherapie" className="text-decoration-none link-info">
						balnéothéraphie et de récupération
					</Link>{" "}
					avec sauna, bain froid et piscine chauffée, sans oublier notre{" "}
					<Link to="/cryotherapie" className="text-decoration-none link-info">
						{" "}
						cabine de cryothérapie
					</Link>
				</p>
			</div>
		</div>
	)
}

export default OfficePresentation
