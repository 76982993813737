import React, { Fragment } from "react"
import team from "./TeamMembers"
import { GiClick } from "react-icons/gi"
import { IconContext } from "react-icons"

const PresentationCards = () => {
	return (
		<Fragment>
			<div className=" ms-1 row row-cols-lg-4 row-cols-md-4 row-cols-sm-2 row-cols-2 me-1">
				{team.map((collab, index) => (
					<Fragment key={index}>
						<div
							className="card col border-0 my-2 h-100"
							data-bs-toggle="modal"
							data-bs-target={`#Modal${index}`}
							style={{ position: "relative" }}
						>
							<img
								src={collab.picture}
								className="card-img-top img-fluid"
								alt={`présentation de ${collab.name}`}
							/>
							<div className="card-body">
								<p className="card-title text-center fs-6 fw-bold row justify-content-center">
									{collab.name}
								</p>
								<p
									className="card-text text-center text-white"
									style={{
										position: "absolute",
										top: "8px",
										left: "16px",
									}}
								>
									<IconContext.Provider
										value={{ size: "35px", color: "#29ACD6" }}
									>
										<GiClick />
									</IconContext.Provider>
								</p>
							</div>
						</div>
						<div
							className="modal fade"
							id={`Modal${index}`}
							tabIndex="-1"
							aria-labelledby={`#ModalLabel${index}`}
							aria-hidden="true"
						>
							<div className="modal-dialog border-0 mx-3 my-3">
								<div className="modal-content ">
									<div className="modal-header">
										<div className="modal-title" id={`#ModalLabel${index}`}>
											<img
												src={collab.picture}
												className="card-img-top"
												alt={`présentation de ${collab.name}`}
											/>
										</div>
									</div>
									<div className="modal-body">
										<h2 className="text-center">{collab.name}</h2>
										<ul className="list-group-flush border-0">
											<h4 className="text-center">Formations</h4>
											{collab.accomplished.map((quality, index) => (
												<li
													className="text-center list-group-item"
													key={`${index}+${collab.localKey}`}
												>
													{quality}
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</Fragment>
				))}
			</div>
		</Fragment>
	)
}

export default PresentationCards
