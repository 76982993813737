import React, { Fragment } from "react"
import accueil from "../assets/officePictures/Locaux_accueil.jpg"
import { BiMailSend, BiPhoneCall } from "react-icons/bi"
import { Link } from "react-router-dom"

const ContactUs = () => {
	const email = "caenkinesports@gmail.com"
	const phonenumber = "09 83 40 25 30 "
	return (
		<Fragment>
			<div></div>
			<img src={accueil} className="img-fluid" alt="accueil du cabinet" />
			<h1 className="text-center text-info h3 mt-2"> Nos coordonnées</h1>
			<div className="container text-center">
				12 Rue Elise Deroche , Quartier Koenig – 14760 Bretteville-sur-Odon
				<br />
				(Possibilité de se garer facilement à proximité du cabinet) <br />{" "}
				Twisto : Arrêt de bus Koenig
				<p className="text-center mt-2">
					<strong> Horaires:</strong> Du lundi au vendredi de 8h à 20h <br />
					<Link to={`mailto:${email}`} className="text-decoration-none">
						{" "}
						<BiMailSend /> {email}
					</Link>{" "}
					<br />
					<Link to={`tel:${phonenumber}`} className="text-decoration-none">
						<BiPhoneCall /> {phonenumber}
					</Link>
				</p>
			</div>
			<h2 className="text-center text-info">Rendez-vous</h2>
			<div className="container text-center">
				<p>
					{" "}
					Les prises de rendez-vous se font via notre secrétariat. N'hésitez pas
					à nous appeler ou envoyer un mail{" "}
				</p>
			</div>
		</Fragment>
	)
}

export default ContactUs
